.rotate10 {
  animation-duration: 1s;
  animation-name: rot;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-fill-mode: revert;
  animation-timing-function: ease-in-out;
}
@keyframes rot {
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
}
