@import './Theme.scss';

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--theme-page-background);
  background-color: var(--theme-page-background);
  color: var(--theme-page-text);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: var(--vazirmatn), sans-serif ;
  ::-webkit-scrollbar {
    display: none;
  }
}

body::-webkit-scrollbar {
  display: none;
}

.AppDrawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: var(--theme-page-background);
  background-color: var(--theme-page-background);
  color: var(--theme-page-text);
  -webkit-tap-highlight-color: transparent;
  font-family: var(--vazirmatn), sans-serif;
}

.drawerTopView {
  width: 4rem;
  height: 0.4rem;
  margin-top: 0.8rem;
  background-color: var(--theme-page-text-lighter);
  border-radius: 0.2rem;
  opacity: 0.4;
  box-shadow: 0 6px 28px rgba(82, 87, 104, 0.1);
}

.searchBox {
  width: 90%;
}

.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
  display: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

//
//@media (min-width: 768px) {
//  .hideScrollBar::-webkit-scrollbar {
//    display: none;
//    //display: inline-block!important;
//  }
//  .hideScrollBar {
//    -ms-overflow-style: auto; /* IE and Edge */
//  }
//}

.clubTabContainer {
  z-index: 5;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--theme-page-background-card);
}

@media (min-width: 992px) {
  .clubTabContainer {
    z-index: 5;
    position: sticky;
    top: 0;
    padding-left: 20%;
    padding-right: 20%;
    background-color: var(--theme-page-background-card);
  }
}

.checkButtonInside {
  width: 4.5rem !important;
  height: 2.4rem !important;
  background-color: var(--theme-color-blue);
  border-radius: 0.3rem;
  color: white;
  line-height: 2.4rem;
  vertical-align: middle !important;
}

.fixed .material-icons {
  direction: ltr;
}

.font {
  font-family: var(--vazirmatn), sans-serif;
}

.background {
  background-color: var(--theme-page-background) !important;
}

.backgroundCard {
  background-color: var(--theme-page-background-card);
}

.titleCenter {
  width: 100%;
  text-align: center;
}

.totalCenter {
  align-items: center !important;
  justify-content: center !important;
}

.top20 {
  margin-top: 20vh;
}

@media (min-width: 768px) {
  .top20 {
    margin-top: 0;
  }
}

.top10 {
  margin-top: 10vh;
}

@media (min-width: 768px) {
  .top10 {
    margin-top: 1rem;
  }
}

.top30 {
  margin-top: 30vh !important;
}

.responsiveWidth {
  width: 100% !important;
  background: var(--theme-page-background);
  z-index: 1;
}

@media (min-width: 768px) {
  .responsiveWidth {
    width: 85% !important;
  }
}

@media (min-width: 992px) {
  .responsiveWidth {
    width: 75% !important;
  }
}

@media (min-width: 1024px) {
  .responsiveWidth {
    width: 65% !important;
  }
}

.mText {
  user-select: none;
  color: var(--theme-page-text) !important;
}

.mTextLight {
  color: var(--theme-page-text-light) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 20px;
  font-family: var(--vazirmatn), sans-serif;
  padding: 0.1rem !important;
  color: var(--theme-color-blue) !important;
  background-color: var(--theme-page-background) !important;
}

//.MuiInputLabel-outlined.MuiInputLabel-shrink {
//  transform: translate(-14px, -14px) scale(0.7) !important;
//}
//
.MuiInputLabel-outlined {
  font-family: var(--vazirmatn), sans-serif ;
  font-size: 20px;
  //transform: translate(-14px, 16px) scale(1) !important;
  color: var(--theme-color-blue-light) !important;
}

.MuiInputBase-input {
  font-family: var(--vazirmatn), sans-serif ;
  font-size: 1rem !important;
  color: var(--theme-page-text) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 0.8rem !important;
}

//.MuiOutlinedInput-input{
//  padding-top: 12px !important;
//  padding-bottom: 12px !important;
//}

//.MuiTextField-root input {
//  /* 14.5px = 18.5px - 4px (notebook: 18.5px is the input's default padding top and bottom) */
//  padding-top: 12px !important;
//  padding-bottom: 12px !important;
//}

//.MuiTextField-root label {
//  top: -6px;
//}

.MuiTextField-root label[data-shrink='true'] {
  top: 0;
}

//.MuiOutlinedInput-notchedOutline{
//  border-color: var(--theme-page-line-color) !important;
//  color: var(--theme-page-line-color) !important;
//}

.mInput {
  color: var(--theme-page-text);
  background-color: var(--theme-page-background-card);
}

.mInput:focus {
  color: var(--theme-page-text);
  background-color: var(--theme-page-background-card);
}

.mInput:read-only {
  color: var(--theme-page-text);
  background-color: var(--theme-page-background-card);
}

.roundedSpinner {
  border-radius: 0.8rem;
  height: 4rem;
}

.MuiSelect-icon {
  color: var(--theme-page-text) !important;
}

.mTextBlue {
  color: var(--theme-color-blue) !important;
}

.mTextLightBlue {
  color: var(--theme-color-blue-light) !important;
}

.mTextRed {
  color: var(--theme-color-red) !important;
}

.mTextGreen {
  color: var(--theme-color-green) !important;
}

.mTextGreenLight {
  color: var(--theme-color-green-light) !important;
}

.mTextGold {
  color: var(--theme-color-gold) !important;
}

.mBackgroundGold {
  background-color: var(--theme-color-gold) !important;
}

.mBackgroundGoldLight {
  background-color: var(--theme-color-gold-light) !important;
}

.mBackgroundBlue {
  background-color: var(--theme-color-blue) !important;
}

.mBackgroundGreen {
  background-color: var(--theme-color-green) !important;
}

.mBackgroundBlueGrey {
  background-color: var(--theme-color-blue-light) !important;
}

.mBackgroundLightBlue {
  background-color: var(--theme-page-line-color) !important;
}

.mBackgroundDark {
  background-color: var(--theme-page-background-dark) !important;
}

.mBackgroundRed {
  background-color: var(--theme-color-red) !important;
}

.mBackgroundLightRed {
  background-color: var(--theme-color-red-light) !important;
}

.mBackgroundGreenLight {
  background-color: var(--theme-color-green-light) !important;
}

.mRounded-1 {
  border-radius: 0.5rem;
}

.mRounded-2 {
  border-radius: 1rem !important;
}

.mRounded-25 {
  border-radius: 1.25rem !important;
}

.mRounded-3 {
  border-radius: 1.5rem !important;
}

.mRounded-4 {
  border-radius: 2rem !important;
}

.mRounded-2i {
  border-radius: 1rem !important;
}

.categoryFrame {
  height: 4rem;
}

.operatorImage {
  width: 5rem !important;
  height: 7rem !important;
}

.swiperRecyclerFirstImage {
  width: 10rem !important;
  height: 10rem !important;
}

.videoSlider {
  width: 94vw !important;
  height: 3rem;
  position: absolute;
  bottom: 0;
  top: unset;
  left: 1vw;
  right: 2vw;
  z-index: 9;
}

.playIcon {
  width: 3.2rem !important;
  height: 3.2rem !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: white !important;
  z-index: 9 !important;
}

.fullScreenIcon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  position: absolute;
  top: unset;
  bottom: 1.8rem;
  right: 0.5rem;
  margin: auto;
  color: white !important;
  z-index: 10 !important;
}

.videoLoader {
  width: 100% !important;
  height: 4rem !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.videoTimeText {
  width: 100%;
  height: 2rem;
  position: absolute !important;
  bottom: 1.6rem;
  top: unset;
  left: 0;
  right: 0;
  color: white !important;
  margin: auto;
}

.discountImage {
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 2rem;
  background-color: rgba(217, 215, 215, 0.5);
  margin: 0.4rem;
  object-fit: cover;
}

.mDivider {
  width: 1px;
  background-color: rgba(138, 138, 138, 0.12);
  height: 5rem;
  padding: 0.1rem;
  margin: 0.1rem;
}

.mDividerDashed {
  border-width: 1px;
  border-color: rgba(138, 138, 138, 0.12);
  border-style: dashed;
  height: 5rem;
  margin: 0.1rem;
}

.operatorLittleCircleImage {
  width: 2.6rem !important;
  height: 2.6rem !important;
  border-radius: 1.3rem;
  margin: 0.4rem;
  object-fit: cover;
}

.successIcon {
  width: 5rem !important;
  height: 5rem !important;
}

.scoreImage {
  width: 2rem;
  height: 2rem;
}

.calculateButton {
  width: 90%;
  margin-right: 3%;
  margin-left: 3%;
  height: 3rem;
  line-height: 3rem !important;
  vertical-align: middle !important;
  background-color: var(--theme-color-blue);
  color: white;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .calculateButton {
    width: 65%;
    margin-right: 3%;
    margin-left: 3%;
    height: 3rem;
    line-height: 3rem !important;
    vertical-align: middle !important;
    background-color: var(--theme-color-blue);
    color: white;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .calculateButton {
    width: 50%;
    margin-right: 3%;
    margin-left: 3%;
    height: 3rem;
    line-height: 3rem !important;
    vertical-align: middle !important;
    background-color: var(--theme-color-blue);
    color: white;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
  }
}

.bottomShadow {
  box-shadow: 10px 10px 50px -15px rgba(115, 115, 115, 0.75);
}

.mainToolbar {
  width: 100%;
  height: 3rem;
  z-index: 10;
  caret-color: transparent !important;
}

.containerFullWidth {
  width: 100% !important;
}

@media (min-width: 768px) {
  .containerFullWidth {
    width: 70% !important;
  }
}

.mainChild {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
}

.littleBorderButton {
  width: auto;
  height: 2.5rem;
  line-height: 2.5rem;
  vertical-align: middle !important;
  color: var(--theme-page-text);
  border: 2px solid var(--theme-color-blue);
  border-radius: 0.5rem;
  font-size: 0.9rem;
}

.videoImage {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  border-radius: 1rem;
  object-fit: contain;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  background-color: var(--theme-page-line-color);
}

.videoImageBlur {
  width: 100% !important;
  height: 100% !important;
  border-radius: 1rem;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  overflow: hidden !important;
}

.categoryImage {
  width: 100%;
  height: 3rem;
  object-fit: cover;
  border-radius: 0.8rem;
}

.categoryText {
  width: 100%;
  height: 3rem;
  position: absolute;
  right: 0;
  line-height: 3rem;
  vertical-align: middle !important;
  text-align: right;
  padding-right: 1.5rem;
  color: white;
}

.redButton {
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  vertical-align: middle !important;
  background-color: var(--theme-color-red);
  color: white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.blueButton {
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  vertical-align: middle !important;
  background-color: var(--theme-color-blue);
  color: white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.borderButton {
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  vertical-align: middle !important;
  color: var(--theme-page-text);
  border-radius: 0.5rem;
  border: 2px solid var(--theme-color-blue);
  margin: 0.5rem;
  font-size: 0.9rem;
}

.secondButton {
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  vertical-align: middle !important;
  color: var(--theme-color-blue);
  border-radius: 0.5rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.simpleBorderButton {
  width: 90%;
  height: 3.5rem;
  line-height: 3.5rem;
  vertical-align: middle !important;
  color: var(--theme-page-text);
  border-radius: 0.5rem;
  border: 2px solid var(--theme-page-text-light);
  margin: 0.5rem;
  font-size: 0.9rem;
  padding: 0.4rem;
}

.darkButton {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  vertical-align: middle !important;
  background-color: var(--theme-page-background-dark);
  color: var(--theme-page-text);
  border-radius: 0.5rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.borderCircle {
  border-radius: 50% !important;
}

.mBorder {
  border-color: var(--theme-page-line-color);
  border-width: 0.1rem;
}

.mBorder1 {
  border-color: var(--theme-page-line-color);
  border-width: 0.2rem;
}

.mBorder1:focus {
  border-color: var(--theme-color-blue);
  border-width: 0.2rem;
}

.mBorder:focus {
  border-color: var(--theme-color-blue);
  border-width: 0.1rem;
}

.mTextLight {
  color: var(--theme-page-text-light);
}

.mTextLighter {
  color: var(--theme-page-text-lighter);
}

.fs-7 {
  font-size: 0.8rem !important;
}

.fs-75 {
  font-size: 0.7rem !important;
}

.fs-8 {
  font-size: 0.6rem !important;
}

.card {
  background-color: var(--theme-page-background-card);
  border-radius: 1rem;
}

.cardBackground {
  background-color: var(--theme-page-background-card);
  border-radius: 1rem;
  padding: 0.2rem;
}

.separatorLine {
  background-color: var(--theme-page-line-color);
  height: 0.1rem;
  width: 100%;
}

.pointer {
  user-select: none;
  cursor: pointer;
}

.fullScreen {
  width: 100%;
  height: 90vh;
}

.loaderWrap {
  width: 100%;
  height: 4rem;
}

.loaderFull {
  width: 100%;
  height: 100%;
}

.emptyImage {
  width: 50vw !important;
  height: auto !important;
}

@media (min-width: 768px) {
  .emptyImage {
    width: 15vw !important;
    height: auto !important;
  }
}

.closeIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.8rem !important;
  height: 1.8rem !important;
  cursor: pointer;
  color: var(--theme-color-blue-light);
  z-index: 10;
}

.submitButtonBlueFrame {
  background-color: #0d6efdff;
  border-radius: 1rem;
  border-width: 0.1rem;
  border-color: #0256d0;
}

.cardWithBorder {
  border-radius: 1.5rem;
  height: 3rem;
  border-color: var(--theme-page-stroke);
  background-color: var(--theme-page-background-card);
  border-width: 0.1rem;
}

.activeBorder {
  border-color: var(--theme-color-blue);
  border-width: 0.15rem;
}

.amountRedLine {
  background-color: red;
  width: 4rem;
  height: 0.1rem;
  position: absolute;
  top: 1.2rem;
}

.hefezImage {
  width: 3rem;
  height: 3rem;
}

.hafezBack {
  width: 100vw;
  height: 100vh;
  opacity: 0.1;
  object-fit: contain;
}

.toolbarContainer {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--theme-page-background-card);
}

.card {
  background: var(--theme-page-background-card);
  border-width: 0;
  border-radius: 1rem;
  display: inline-block;
  position: relative;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

//.card-1:hover {
//  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
//}

.card-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-box {
  box-shadow: 0 5px 12px rgba(82, 87, 104, 0.06), 0 -5px 12px rgba(82, 87, 104, 0.06);
}

.card-box1 {
  box-shadow: 0 3px 28px rgba(82, 87, 104, 0.1), 0 -3px 28px rgba(82, 87, 104, 0.1);
}

.card-charge {
  flex: 0 0 auto;
  width: auto;
  height: 3rem;
  color: var(--theme-color-green);
  background-color: var(--theme-color-green-light);
  border-color: var(--theme-color-green);
  border-width: 0.12rem;
  border-radius: 0.8rem;
  //box-shadow: 0 3px 8px rgba(82, 87, 104, 0.1), 0 -6px 28px rgba(82, 87, 104, 0.1);
}

.card-charge-border {
  flex: 0 0 auto;
  width: auto;
  height: 3rem;
  color: var(--theme-color-green);
  border-color: var(--theme-color-green-light);
  border-width: 0.12rem;
  border-radius: 0.8rem;
  //box-shadow: 0 3px 8px rgba(82, 87, 104, 0.1), 0 -6px 28px rgba(82, 87, 104, 0.1);
}

.width-auto {
  flex: 0 0 auto;
  width: auto;
}

.card-bottom {
  box-shadow: 0 6px 28px rgba(82, 87, 104, 0.1);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.horizontalGridWidth {
  flex: 0 0 auto;
  width: 23%;
}

@media (min-width: 768px) {
  .horizontalGridWidth {
    flex: 0 0 auto;
    width: 16%;
  }
}

@media (min-width: 1024px) {
  .horizontalGridWidth {
    flex: 0 0 auto;
    width: 13%;
  }
}

.squareHorizontalGridWidth {
  flex: 0 0 auto;
  width: 32%;
}

@media (min-width: 768px) {
  .squareHorizontalGridWidth {
    flex: 0 0 auto;
    width: 22%;
  }
}

@media (min-width: 1024px) {
  .squareHorizontalGridWidth {
    flex: 0 0 auto;
    width: 13%;
  }
}

.hidePointer {
  cursor: none;
  caret-color: transparent !important;
}

.textareaSimple {
  border-style: none;
  border-color: Transparent;
  border-width: 0;
  border-image: unset;
  background-color: transparent;
  overflow: auto;
  outline: none;
  line-height: unset;
  resize: none;
}

.fullWidth {
  width: 100% !important;
  height: auto;
}

.toolsTabContainer {
  z-index: 5;
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  background-color: var(--theme-page-background-card);
}

@media (min-width: 992px) {
  .toolsTabContainer {
    z-index: 5;
    position: fixed;
    top: 4rem;
    padding-left: 20%;
    padding-right: 20%;
    background-color: var(--theme-page-background-card);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiTab-textColorInherit {
  color: var(--theme-color-blue-light) !important;
  opacity: 0.6;
}

.MuiTab-root.Mui-selected {
  color: var(--theme-color-blue) !important;
  font-weight: bold;
}

.MuiTab-root {
  color: var(--theme-page-text-light) !important;
  font-weight: normal;
}

.MuiTab-textColorInherit.Mui-selected {
  color: var(--theme-page-text) !important;
  font-weight: bold;
  opacity: 1;
}

.MuiTabs-indicator {
  background-color: var(--theme-color-blue) !important;
}

.MuiBottomNavigationAction-label.Mui-selected {
  color: var(--theme-color-blue) !important;
  opacity: 1;
}

.MuiBottomNavigationAction-root.Mui-selected {
  color: var(--theme-color-blue) !important;
  opacity: 1;
}

.MuiBottomNavigationAction-label {
  color: var(--theme-page-text-lighter) !important;
  opacity: 0.6;
}

.MuiBottomNavigationAction-root {
  color: var(--theme-page-text-lighter) !important;
  opacity: 0.6;
}

.MuiBottomNavigation-root {
  background: var(--theme-page-background-card);
  background-color: var(--theme-page-background-card);
}
