.swiperStyle {
  height: 4rem !important;
  line-height: 4rem;
  vertical-align: center;
  white-space: nowrap;
  display: inline;
}

.swiperCenterFrame {
  height: 4rem !important;
}

.gradientBottomToTop {
  z-index: 10;
  background-image: linear-gradient(to top, var(--theme-page-background), transparent);
}

.swiperParent {
  margin-top: 10rem !important;
}

.swiperParentPelak {
  margin-top: 10rem !important;
}

.slider-entity {
  flex: 1;
  width: 100%;
}
