.greenButton {
  width: auto;
  height: 2rem;
  line-height: 2rem;
  vertical-align: middle !important;
  color: var(--theme-color-green);
  background-color: var(--theme-color-green-light);
  border: 1px solid var(--theme-color-green-light);
  border-radius: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}
