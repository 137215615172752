.activeCampaignImage {
  width: 100% !important;
  height: auto !important;
  min-height: 5rem !important;
  max-height: 8rem !important;
  object-fit: fill;
}

@media (min-width: 768px) {
  .activeCampaignImage {
    width: 100% !important;
    height: auto !important;
    min-height: 8rem !important;
    max-height: 12rem !important;
    object-fit: fill;
  }
}
.campaignText {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}

.verticalLine {
  background-color: var(--theme-page-line-color);
  height: 6rem;
  width: 0.1rem;
  border-radius: 0.05rem;
}

.coinImageLarge {
  width: 2.3rem !important;
  height: 2.3rem !important;
  object-fit: contain;
}

.coinImage {
  width: 2rem !important;
  height: 2rem !important;
  object-fit: contain;
}

.littleCoinImage {
  width: 1.3rem !important;
  height: 1.3rem !important;
  object-fit: contain;
}

.campaignRewardImage {
  width: 5rem !important;
  height: 5rem !important;
  object-fit: fill;
}

.circleChance {
  width: 1.5rem !important;
  height: 1.5rem !important;
  line-height: 1.2rem !important;
  font-size: 0.9rem !important;
}
