.fullScreen {
  width: 100%;
  height: 100vh;
}

.fullScreenToolbar {
  width: 100%;
  height: calc(100vh - 3rem);
}

.fullScreenContainer {
  width: 100%;
  height: calc(100vh - 4rem);
}

  .fullScreenToolbarContainer {
    width: 100%;
    height: calc(100vh - 8rem);
  }