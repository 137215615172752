.loginFrame {
  border-radius: 1rem;
  background-color: var(--theme-color-blue);
  height: 6rem;
}

.userProfileImage {
  width: 3.4rem !important;
  height: 3.4rem !important;
  border-radius: 1.7rem;
  padding: 0.8rem;
  background-color: white;
}

.defaultImage {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: 0.8rem;
}

.defaultImageLittle {
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin: 0.4rem;
}

.loginText {
  color: var(--theme-color-gold);
}

.enterButton {
  background-color: var(--theme-color-blue);
  color: white;
  height: auto;
  border-radius: 1rem;
  padding: 0.8rem;
  width: 100%;
}

.loginLoaderNumber {
  width: 100%;
  position: absolute;
  top: 0.8rem;
  left: 0;
  right: 0;
}

.mainLoaderLogin {
  width: 100%;
  position: absolute;
  top: 0.1rem;
  bottom: 0;
  left: 0;
  right: 0;
}
