.shortcutCategoriesIconImage {
  width: 100% !important;
  height: 3rem !important;
  object-fit: contain;
}

.shortcutCategoriesText {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.bannerIconImage {
  width: auto !important;
  height: 8rem !important;
}

.videoBannerIconImage {
  width: 14rem !important;
  height: 8rem !important;
}

.shortVideoIconImage {
  width: 9rem !important;
  height: 14rem !important;
}

.shortcutFrame {
  padding: 0.1rem;
}

.shortcutIconImage {
  width: 100% !important;
  height: auto !important;
  min-height: 3.5rem !important;
  min-width: 3.5rem !important;
  max-height: 5rem !important;
  max-width: 5rem !important;
}

@media (min-width: 768px) {
  .shortcutIconImage {
    width: 4.5rem !important;
    height: 4.5rem !important;
  }
}

@media (min-width: 1024px) {
  .shortcutIconImage {
    width: 5rem !important;
    height: 5rem !important;
  }
}

.shortcutIconImageSquare {
  width: 6rem !important;
  height: 6rem !important;
}

@media (min-width: 768px) {
  .shortcutIconImageSquare {
    width: 7rem !important;
    height: 7rem !important;
  }
}

@media (min-width: 768px) {
  .shortcutIconImageSquare {
    width: 8rem !important;
    height: 8rem !important;
  }
}



.shortcutRectangularIconImage {
  width: 100% !important;
  height: 11rem !important;
}

.shortcutShowMoreImage {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
  padding: 0.6rem !important;
}

.tabMargin {
  margin-top: 3rem;
}

.arrowFavorite {
  color: var(--theme-page-text-lighter);
  background-color: var(--theme-page-background-dark);
}

.arrowCircleBackground {
  background-color: var(--theme-page-background-dark);
  color: var(--bs-blue);
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding-left: 0.5rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  border-radius: 50% !important;
}

.fullHeight {
  height: 100vh;
}

.toolbarSearchFrame {
  height: 3rem;
  background-color: var(--theme-page-background-dark);
  border-radius: 1rem;
  z-index: 12 !important;
}

.seeAllButton {
  z-index: 5;
  width: 8rem;
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  background-color: var(--theme-color-blue);
  color: white;
  border-radius: 1.1rem;
}

.cardTitle {
  z-index: 5;
}

.topNegativeMargin {
  margin-top: -0.7rem;
}

.topNegativeMargin1 {
  margin-top: -1rem;
}

.fullScreenWithToolbar {
  width: 100%;
  height: auto;
  padding-bottom: 4rem;
}
