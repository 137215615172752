.textarea {
  border-style: none;
  border-color: Transparent;
  border-width: 0;
  border-image: unset;
  background-color: transparent;
  overflow: auto;
  outline: none;
  line-height: unset;
  resize: none;
}

.noteTitle {
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
}
.fullPage {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.noteContent {
  height: 100%;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
}

.noteIconImage {
  width: 1.7rem !important;
  height: 1.7rem !important;
  object-fit: cover;
  filter: var(--theme-color-blue);
  margin-top: 0.5rem;
}

.noteColorFrame {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 0.5rem;
}

.noteContainer {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 10rem;
}

.MuiSlider-track {
  height: 6px !important;
  background-color: var(--theme-color-blue) !important;
}

.MuiSlider-rail {
  height: 6px !important;
  background-color: var(--theme-page-line-color) !important;
}

.MuiSlider-valueLabel {
  color: var(--theme-color-blue) !important;
}

.MuiSlider-thumb {
  height: 15px !important;
  width: 15px !important;
  border-color: var(--theme-page-text-lighter) !important;
  border-width: 2px !important;
  background-color: var(--theme-color-blue) !important;
}

.dialogImage {
  color: var(--theme-color-blue) !important;
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 2rem;
  padding: 0.5rem;
  background-color: var(--theme-page-line-color);
}
