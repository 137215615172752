.voteBottom {
  margin-bottom: 3rem;
}

.voteTabContainer {
  z-index: 5;
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  background-color: var(--theme-page-background-card);
}

@media (min-width: 992px) {
  .voteTabContainer {
    z-index: 5;
    position: fixed;
    top: 3rem;
    padding-left: 20%;
    padding-right: 20%;
    background-color: var(--theme-page-background-card);
  }
}

.voteTabMargin {
  margin-top: 3rem;
}

.voteResultImage {
  width: 100%;
  --bs-aspect-ratio: calc(34 / 64 * 100%);
}

.predictLargeImage {
  width: 6rem !important;
  height: 6rem !important;
}

.predictSmallImage {
  width: 2rem !important;
  height: 2rem !important;
}

.predictEditField {
  width: 2.5rem !important;
  height: 2.2rem !important;
  padding-right: 0.8rem !important;
  border-radius: 0.5rem;
  border: 1px solid var(--theme-color-green-light);
}

.swiperSlide {
  height: max-content !important;
}
