.ramazanMehrabLeft {
  left: 0;
  width: auto !important;
  height: 100% !important;
  object-fit: fill;
  z-index: 8;
  background-size: cover;
}

.ramazanMehrabRight {
  right: 0;
  width: auto !important;
  height: 100% !important;
  object-fit: fill;
  z-index: 8;
  background-size: cover;
}

.ramazanMainBottom {
  bottom: 0;
  width: 100vw !important;
  height: auto !important;
  object-fit: fill;
  background-size: cover;
  z-index: 5;
}

.ramazanDetail {
  padding-right: 15vw;
  padding-left: 15vw;
  overflow: hidden;
  padding-top: 20vh;
  z-index: 11;
}

.playSize {
  width: 5rem !important;
  height: 5rem !important;
  object-fit: fill;
}
