.Calendar__day {
  aspect-ratio: 1/1 !important;
  color: var(--theme-page-text);
  height: auto !important;
}
.Calendar__day.-selected {
  border-radius: 500rem;
  border-width: 1px;
  border-color: var(--theme-color-gold);
  background: transparent;
  color: var(--theme-page-text) !important;
}
.Calendar__monthYear {
  color: var(--theme-page-text);
}

.Calendar__monthSelector,
.Calendar__yearSelector {
  background: var(--theme-page-background) !important;
}

.Calendar__monthArrowWrapper {
  filter: var(--theme-page-image-filter-invert);
}

.Calendar__sectionWrapper {
}

.Calendar__day.-today {
  border-radius: 500rem;
  background: var(--theme-color-blue);
  color: white !important;
}

.Calendar__monthYear > *.-activeBackground {
  background: green !important;
  color: white !important;
}

.calendarContainer {
  box-shadow: none !important;
  width: 100% !important;
}

.calendarButton {
  width: 5rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 2rem;
}

.calendarContainer .heading .next,
.calendarContainer .heading .prev {
  width: 2.5rem !important;
  height: 2.5rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calendarContainer .heading svg {
  width: 1rem !important;
  height: 1rem !important;
}
