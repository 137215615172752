.indexImageFrame {
  width: 3.2rem;
  height: 3.2rem;
}

.soreDetailPlayIndexFrame {
  width: 2.2rem;
  height: 4.6rem;
}
.frameImage {
  filter: var(--theme-page-image-filter-invert);
}
