.swiperStyle {
  height: 4rem !important;
  line-height: 4rem;
  vertical-align: center;
  white-space: nowrap;
  display: inline;
}

.plateContainer {
  width: 100%;
  margin-top: 2rem;
  height: 100%;
}

.nationalCodeContainer {
  width: 100%;
  height: 100%;
}

.slick-slider .slick-track {
  max-height: 50vh;
}

//.slick-track {
//  display: flex !important;
//}
//
//.slick-slide {
//  height: auto!important;
//}

.swiperCenterFrame {
  height: 4rem !important;
}

.gradientBottomToTop {
  z-index: 10;
  background-image: linear-gradient(to top, var(--theme-page-background), transparent);
}

.swiperParent {
  margin-top: 10rem !important;
}

.swiperParentPelak {
  margin-top: 40vw !important;
}

.slider-entity {
  flex: 1;
  width: 100%;
}
