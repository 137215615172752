.billBundleIcon {
  width: 3.2rem !important;
  height: 3.2rem !important;
}

.barcode_frame {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  object-fit: cover;
  top: 0;
}

.barcode {
  width: 100vw;
  height: 100%;
  position: fixed;
}
