.rotate {
  animation-duration: 4s;
  animation-name: rota;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-play-state: running;
}
@keyframes rota {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(720deg);
  }
  75% {
    transform: rotate(1080deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}
