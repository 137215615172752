.toolbarTopFrame {
  height: 4rem;
  background-color: var(--theme-page-background-card);
}

.containerFrame {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.toolbarSearchFrame {
  height: 3rem;
  background-color: var(--theme-page-background-dark);
  border-radius: 1rem;
  z-index: 12 !important;
}

.logoImageText {
  height: 1rem !important;
  min-width: 5rem !important;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.toolbarItems {
  color: var(--theme-page-text-light);
  height: 80%;
}

.searchIcon {
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.bottomNavFrame {
  z-index: 6;
  height: 4rem;
  background: var(--theme-page-background-card) !important;
  background-color: var(--theme-page-background-card) !important;
  box-shadow: 0 6px 28px rgba(82, 87, 104, 0.1);
}

.profileImageStyle {
  width: 3rem !important;
  height: 3rem !important;
  flex-shrink: 0;
  border-radius: 1.5rem;
  padding: 0.8rem;
  filter: var(--theme-color-blue) !important;
  background-color: var(--theme-page-background-dark);
}
