.tabContainer {
  z-index: 5;
  position: fixed;
  left: 0;
  right: 0;
  background-color: var(--theme-page-background-card);
}

@media (min-width: 992px) {
  .tabContainer {
    z-index: 5;
    position: fixed;
    padding-left: 20%;
    padding-right: 20%;
    background-color: var(--theme-page-background-card);
  }
}

.tabMargin {
  margin-top: 3rem;
}
