.clubBottom {
  margin-bottom: 4rem;
}

.clubTabMargin {
  margin-top: 3rem;
}

.scoreHistoryFrame {
  background-color: #fdb000;
}

.myDiscountsFrame {
  background-color: #ff3636;
  opacity: 0.8;
}

.clubAppLogoImage {
  width: 5rem !important;
  height: 5rem !important;
}
