.bottomNavImage {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.bottomNavImageScale {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.animation {
  animation-duration: 0.5s;
  animation-name: scl;
  animation-iteration-count: unset;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes scl {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
