.nahjLogo {
  width: 8rem !important;
  height: 5rem !important;
  object-fit: contain;
}

.nahjDetailText {
  background: var(--theme-page-background);
  background-color: var(--theme-page-background);
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 20vw;
  padding-left: 20vw;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

.nahjDetail {
  overflow: hidden;
  padding: 5vh 10vw;
}

.nahjMehrabLeft {
  left: 0;
  width: auto !important;
  height: 100% !important;
  object-fit: fill;
  z-index: 8;
  background-size: cover;
}

.nahjMehrabRight {
  right: 0;
  width: auto !important;
  height: 100% !important;
  object-fit: fill;
  z-index: 8;
  background-size: cover;
}

.nahjMainBottom {
  bottom: 0;
  width: 100vw !important;
  height: auto !important;
  object-fit: fill;
  background-size: cover;
  z-index: 5;
}

.mainTopFrame {
  margin-top: 20vh;
}

.nahjBackContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 3rem !important;
  left: 0;
  right: 0;
}

.nahjBackLeft {
  left: 0;
  width: 1rem !important;
  height: 100% !important;
  object-fit: fill;
  z-index: 8;
  background-size: cover;
}

.nahjBackRight {
  right: 0;
  width: 1rem !important;
  height: 100% !important;
  object-fit: fill;
  background-size: cover;
  z-index: 7;
}
.nahjBackBottom {
  bottom: 0;
  width: 100vw !important;
  height: 1rem !important;
  object-fit: fill;
  background-size: cover;
  z-index: 5;
}

.nahjBackTop {
  top: 3rem !important;
  width: 100vw !important;
  height: 1rem !important;
  object-fit: fill;
  background-size: cover;
  z-index: 5;
}

.nahjItemFrame {
  width: 100% !important;
  height: 4rem !important;
  object-fit: contain;
}
