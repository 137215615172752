.light {
  --theme-color-green: #0c6c3c;
  --theme-color-green-light: #b5dfca;
  --theme-color-green-lighter: #edf7ed;
  --theme-color-red-light: #fdeded;
  --theme-color-red: #d32f2f;
  --theme-color-blue: #304ffe;
  --theme-color-blue-light: #9cb0d9;
  --theme-color-gold: #ffad42;
  --theme-color-gold-light: #fff4e5;
  --theme-page-background: #fff;
  --theme-page-background-card: #fff;
  --theme-page-background-dark: #f0f3fa;
  --theme-page-line-color: rgba(156, 176, 217, 0.2);
  --theme-page-text: #000051;
  --theme-page-text-light: #262626;
  --theme-page-text-lighter: #545454;
  --theme-page-stroke: rgba(38, 38, 38, 0.87);
  --theme-page-image-filter-invert: invert(0);
}

.dark {
  --theme-color-green: #82e5a5;
  --theme-color-green-light: #b5dfca;
  --theme-color-green-lighter: #edf7ed;
  --theme-color-red-light: #fdeded;
  --theme-color-red: #e58282;
  --theme-color-blue: #536dfe;
  --theme-color-blue-light: #9cb0d9;
  --theme-color-gold: #ffad42;
  --theme-color-gold-light: #fff4e5;
  --theme-page-background: #1f1f1f;
  --theme-page-background-card: #000;
  --theme-page-background-dark: #363636;
  --theme-page-line-color: rgba(156, 176, 217, 0.2);
  --theme-page-text: #fff;
  --theme-page-text-light: #989898;
  --theme-page-text-lighter: #707070;
  --theme-page-stroke: rgba(222, 222, 222, 0.87);
  --theme-page-image-filter-invert: invert(1);
}
