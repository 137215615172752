.fullPage {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.fullPageToolbar {
  width: 100%;
  height: calc(100vh - 3rem);
  overflow: hidden;
  display: flex;
}

.topMargin {
  padding-top: 7rem;
}

.bottomMargin {
  padding-bottom: 4rem;
}
