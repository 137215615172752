.ima-ad-container {
  top: 0 !important;
}

.vjs_video_3_ima-ad-container {
  top: 0 !important;
}

.vjs-current-time {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  display: inline-block !important;
  font-family: IRANSans, sans-serif !important;
}

.vjs-time-divider {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 0.5em !important;
  display: inline-block !important;
}

.vjs-play-progress:before {
  width: 1.2em !important;
  height: 1.2em !important;
  background-color: red !important;
  border-radius: 0.5rem !important;
  content: '' !important;
  color: red !important;
  stroke-width: 0.2rem !important;
  stroke: red !important;
  top: -0.2rem;
}

.vjs-volume-level:before {
  width: 0.8em !important;
  height: 0.8em !important;
  background-color: red !important;
  border-radius: 0.4rem !important;
  content: '' !important;
  color: red !important;
  stroke-width: 0.2rem !important;
  stroke: red !important;
}

.vjs-control-bar {
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent) !important;
}

.vjs-load-progress {
  background-color: gray !important;
}

.vjs-slider-bar {
  background-color: red !important;
}

.vjs-volume-bar {
  background-color: gray !important;
}

.vjs-volume-level {
  background-color: red !important;
}

.videoAdUiPreSkipContainer {
  background-color: #262626 !important;
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
  font-family: IRANSans, sans-serif !important;
  padding: 0.4rem;
}

.videoAdUiSkipButton {
}

.videoAdUiSkipButtonExperimentalText {
}
.videoAdUiSkipIcon {
}

.videoAdUiAttributionContainer {
}

.videoAdUiLearnMore {
  background-color: #262626 !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  font-family: IRANSans, sans-serif !important;
  padding: 0.4rem;
}

.vjs-duration {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.vjs-duration {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  display: inline-block !important;
}

.vjs-remaining-time {
  display: none !important;
}

.textarea {
  border-style: none;
  border-color: Transparent;
  border-width: 0;
  border-image: unset;
  background-color: transparent;
  overflow: auto;
  outline: none;
  line-height: unset;
  resize: none;
}
