.rowHeight {
  height: 11vh;
  width: 100%;
}

.sideFrame {
  direction: ltr;
  width: 100vw;
}
@media (min-width: 576px) {
  .sideFrame {
    width: 576px;
  }
}

.fade-enter {
  max-width: 0;
  opacity: 0;
}

.fade-enter-active {
  max-width: 11vh;
  opacity: 1;
  transition: all 400ms;
}

.fade-exit {
  max-width: 11vh;
  opacity: 1;
}

.fade-exit-active {
  max-width: 0;
  opacity: 0;
  transition: all 400ms;
}

.orangeColor {
  color: var(--bs-orange) !important;
}

.textarea {
  border-style: none;
  border-color: Transparent;
  border-width: 0;
  border-image: unset;
  background-color: transparent;
  overflow: auto;
  outline: none;
  line-height: unset;
  resize: none;
}

.stackText {
  width: 100%;
  height: 2.8rem;
  overflow: scroll;
  overflow-y: hidden;
  direction: ltr !important;
}
