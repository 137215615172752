.barcode_frame {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  object-fit: cover;
  top: 0;
}

.barcode {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
